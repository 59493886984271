import GuidUtils from '@/shared/utils/guid'

export default class QRCodeModel {
  constructor({
    id,
    valueMeal,
    registrationdate
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.valueMeal = valueMeal,
    this.registrationdate = registrationdate
  }
}