import QrcodeMealRepository from '@/shared/http/repositories/socialProject/qrcode-meals'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import QrcodeRepository from '@/shared/http/repositories/socialProject/qrcode'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import QrCodeCriteria from '@/shared/models/criteria/qrcodecriteria'
import QRCodeModel from '@/shared/models/qrcodeModel'
import QRCodeModelValue from '@/shared/models/qrcodeModelValue'
import moment from 'moment'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
  },

  data: () => ({
    criteria: new QrCodeCriteria(),
    qrcodeModel: new QRCodeModel(),
    qrcodeValueModel: new QRCodeModelValue(),
    showModalDeleteQrcode: false,
    showModalEditQrcode: false,
    mealsQuantity: 0,
    totalQRCode: 0,
    typeSelected: null,
    selected: {},
    emptyTextQrcode: 'Não tem QrCode gerado no momento',
    qrCodes: [],
    valueMeal: null,
    fieldsQrCodes:[
      {
				key: 'name',
				label: 'PORTADOR',
				class: 'left-header-border text-center left-header-border',
				sortable: true,
        formatter: (value, key, item) => {
          if (!item) return 'N/D'
          return item.name.toUpperCase()
        }
			},
      {
				key: 'mealsQuantity',
				label: 'REFEIÇÕES PERMITIDAS / DIA',
				class: 'middle-header text-center',
			},
      {
				key: 'registrationDate',
				label: 'QRCODE CRIADO EM',
				class: 'middle-header text-center',
				sortable: true,
        formatter: (value) => {
          if (!value) return 'N/D'

          const dateformatted = moment(value).format("DD/MM/YYYY HH:mm")
          return dateformatted
        }
			},
      {
				key: 'type',
				label: 'FUNÇÃO',
				class: 'middle-header text-center',
				sortable: true,
        formatter: (value, key, item) => {
          if (!item) return 'N/D'

          const checkType = item.isEmployee ? 'FUNCIONÁRIO' : item.isSingle ? 'VISITANTE' :
                            item.studentId != null ? 'ESTUDANTE' : 'PROFESSOR'

          return checkType
        }
			},
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 20,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'QRCodes Gerados',
            active: true
          }
        ]
      }
		}
	},

  created() {
    this.getAll()
    this.onGetValueMeal()
  },

  methods: {
    getAll() {
      loading.push()
      QrcodeRepository.GetAll(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.qrCodes = res.data.data
          this.totalQRCode = res.data.count
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar QrCode', 'ERRO')
        })
    },

    onSetTypeQrCode(type) {
      if (type === 'employee') {
        this.typeSelected = 'Funcionário'
        this.criteria.qrCodeType = 'employee'
        return
      }
      
      if (type === 'single') {
        this.typeSelected = 'Visitante'
        this.criteria.qrCodeType = 'single'
        return
      }

      if (type === 'student') {
        this.typeSelected = 'Estudante'
        this.criteria.qrCodeType = 'student'
        return
      }

      if (type === 'teacher') {
        this.typeSelected = 'Professor'
        this.criteria.qrCodeType = 'teacher'
        return
      }

      if (type === 'clear') {
        this.typeSelected = null
        this.criteria.qrCodeType = null
        return
      }
    },

    selectRow(item, onDelete) {
      if  (!item) return

      if (onDelete) {
        this.selected = item
        this.id = item.id
        this.showModalDeleteQrcode = true
        return
      }

      this.selected.id = item.id
      this.selected.name = item.name
      this.selected.isSingle = item.isSingle
      this.selected.rg = item.rg
      this.mealsQuantity = item.mealsQuantity
      this.showModalEditQrcode = true
    },

    onDelete() {
      loading.push()
      QrcodeRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('QRCode excluído com sucesso!', 'EXCLUSÃO')
          this.showModalDeleteQrcode = false
          this.getAll()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir QRCode', 'ERRO')
        })
    },

    editQrcode(obj) {
      this.qrcodeModel = obj
      this.qrcodeModel.mealsQuantity = parseInt(this.mealsQuantity)
      loading.push()
      QrcodeRepository.UpdateQRCode(this.qrcodeModel)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('QRCode editado com sucesso!', 'EXCLUSÃO')
          this.closeModalEditQRCode()
          this.getAll()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar QRCode', 'ERRO')
        })
    },

    onSaveValueMeal() {
      if (!this.valueMeal) {
        toast.info('Insira um valor para as refeições', 'VALOR DA REFEIÇÃO')
        this.valueMeal = null
        return
      }

      this.qrcodeValueModel.valueMeal = parseFloat(this.valueMeal.replaceAll('R$', '').replaceAll(',', '.'))
      this.qrcodeValueModel.registrationdate = moment().format()

      QrcodeMealRepository.PostValueMeal(this.qrcodeValueModel)
        .then(res => {
          if (!IsSuccessCode(res)) {
            if (res.status == 204) {
              toast.info('Insira um valor para as refeições', 'VALOR DA REFEIÇÃO')
              this.valueMeal = null
              return
            }
            return Promise.reject()
          }
          toast.success('Valor da refeição atualizada com sucesso!', 'VALOR DA REFEIÇÃO')
          this.valueMeal = res.data.data.valueMeal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar o valor da refeição', 'ERRO')
        })
    },

    onGetValueMeal() {
      QrcodeMealRepository.GetValueMeal()
        .then(res => {
          if (!IsSuccessCode(res)) {
            if (res.status == 204) {
              toast.warning('Nenhum valor da refeição inserido', 'VALOR DA REFEIÇÃO')
              return
            }
            return Promise.reject()
          }

          if (res.data.data != null) {
            this.valueMeal = res.data.data.valueMeal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
          } else {
            toast.warning('Nenhum valor da refeição inserido', 'VALOR DA REFEIÇÃO')
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar o valor da refeição', 'ERRO')
        })
    },

    closeModalEditQRCode() { 
      this.selected = {}
      this.qrcodeModel = new QRCodeModel()
      this.showModalEditQrcode = false
    }
  }
}
